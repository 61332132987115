import { addHorseTracksToNav } from "@/utilities/horseTracks";

import DesktopNav from "./components/DesktopNav";
import MobileNav from "./components/MobileNav";

const Nav = ({ horses, menus }) => {
  const navMenus = addHorseTracksToNav(horses, menus ?? {});
  return (
    <>
      <DesktopNav menus={navMenus} />
      <MobileNav menus={navMenus} />
    </>
  );
};

export default Nav;
